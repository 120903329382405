/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable import/exports-last */
'use client'

import classNames from 'classnames'
import styles from './Captcha.module.css'
import useLocale from '@/i18n/useLocale'
import { CaptchaProps, FriendlyCaptchaError } from './types'
import { type ComponentType, createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { Localization, WidgetInstance } from 'friendly-challenge'
import { delay, getDeferredPromise, noop } from '@betterplace/utils'

const LANG_DE_CUSTOM: Localization = {
  text_init: 'Initialisierung …',

  text_ready: 'Anti-Roboter-Verifizierung',
  button_start: 'Hier klicken',

  text_fetching: 'Herausforderung laden …',

  text_solving: 'Verifizierung, dass du ein Mensch bist …',
  text_completed: 'Du bist ein Mensch',
  text_completed_sr: 'Automatische Spamprüfung abgeschlossen',

  text_expired: 'Verifizierung abgelaufen',
  button_restart: 'Erneut starten',

  text_error: 'Verifizierung fehlgeschlagen',
  button_retry: 'Erneut versuchen',
  text_fetch_error: 'Verbindungsproblem mit',
}

const LANG_EN_CUSTOM: Localization = {
  text_init: 'Initializing …',

  text_ready: 'Anti-Robot Verification',
  button_start: 'Click to start verification',

  text_fetching: 'Fetching Challenge …',

  text_solving: 'Verifying you are human …',
  text_completed: 'You are human',
  text_completed_sr: 'Automatic spam check completed',

  text_expired: 'Anti-Robot verification expired',
  button_restart: 'Restart',

  text_error: 'Verification failed',
  button_retry: 'Retry',
  text_fetch_error: 'Failed to connect to',
}

type CaptchaContextType = {
  widget: WidgetInstance | null
  setWidget: (widget: WidgetInstance | null) => void
  setSolve: (solve: () => Promise<string | undefined>) => void
  solve: () => Promise<string | undefined>
  reset: () => void
  resetTrigger: number
}
const noopSolve = () => Promise.resolve(undefined)

const CaptchaContext = createContext<CaptchaContextType>({
  widget: null,
  setWidget: noop,
  setSolve: noop,
  reset: noop,
  resetTrigger: 0,
  solve: noopSolve,
})

export function useCaptchaContext() {
  const { solve, reset } = useContext(CaptchaContext)
  return {
    reset,
    solve,
  }
}

export function CaptchaProvider({ children }: { children: React.ReactNode }) {
  const [widget, setWidget] = useState<WidgetInstance | null>(null)
  const [resetTrigger, setResetTrigger] = useState(0)
  const reset = useCallback(() => {
    setResetTrigger(Math.random())
  }, [])
  const [solveState, setSolveState] = useState<{ cb: () => Promise<string | undefined> }>({
    cb: () => Promise.resolve(undefined),
  })
  const setSolve = useCallback((cb: () => Promise<string | undefined>) => {
    setSolveState({ cb })
  }, [])
  const solve = useMemo(() => solveState.cb, [solveState])
  return (
    <CaptchaContext.Provider value={{ widget, setWidget, solve, setSolve, reset, resetTrigger }}>
      {children}
    </CaptchaContext.Provider>
  )
}

function useStabilisedCallback<T extends (...args: any[]) => any>(cb: T | undefined) {
  const callbackRef = useRef<T | undefined>(cb)
  useEffect(() => {
    callbackRef.current = cb
  }, [cb])
  const callback = useCallback(
    (...args: Parameters<T>) => {
      callbackRef.current?.(...args)
    },
    [callbackRef]
  )
  return callback
}

function Captcha({
  siteKey,
  endpoint,
  onSuccess: onSuccessCb,
  onError: onErrorCb,
  onStart: onStartCb,
  onReset: onResetCb,
  fieldName = 'captcha_solution',
  invertColors = false,
}: CaptchaProps) {
  const onError = useStabilisedCallback(onErrorCb)
  const onSuccess = useStabilisedCallback(onSuccessCb)
  const onReset = useStabilisedCallback(onResetCb)
  const onStart = useStabilisedCallback(onStartCb)
  const containerRef = useRef<HTMLDivElement>(null)
  const locale = useLocale()

  const { setWidget, setSolve, resetTrigger } = useContext(CaptchaContext)
  const initialiseWidget = useCallback(() => {
    if (!containerRef.current) return
    const { promise, resolve } = getDeferredPromise<string | undefined>()
    const doneCallback = async (solution: string) => {
      onSuccess(solution)
      await delay(0) // next tick to make sure the form is updated
      resolve(solution)
    }

    const errorCallback = (error: FriendlyCaptchaError) => {
      // get the field value to store information about the current state, i.e.
      // .EXPIRED
      // .ERROR
      // .HEADLESS_ERROR
      // .UNSTARTED
      // .FETCHING
      // .UNFINISHED
      const field = containerRef.current?.querySelector(`input[name="${fieldName}"]`) as HTMLInputElement | null
      onError(error, field?.value)
      resolve(undefined) //   We don't want to error out here
    }

    const widget = new WidgetInstance(containerRef.current, {
      sitekey: siteKey,
      startMode: 'focus',
      startedCallback: onStart,
      language: locale === 'en' ? LANG_EN_CUSTOM : LANG_DE_CUSTOM,
      puzzleEndpoint: endpoint,
      solutionFieldName: fieldName,
      doneCallback,
      errorCallback,
    })
    const solve = async () => {
      await widget.start()
      return await promise
    }

    return [widget, solve] as const
  }, [siteKey, onStart, locale, endpoint, fieldName, onSuccess, onError])

  useEffect(() => {
    const result = initialiseWidget()
    if (!result) return
    const [widget, solve] = result
    setWidget(widget)
    setSolve(solve)
    return () => {
      widget?.reset()
      onReset?.()
      setWidget(null)
      setSolve(noopSolve)
    }
  }, [initialiseWidget, resetTrigger, onReset, setWidget, setSolve])

  return (
    <div
      ref={containerRef}
      className={classNames('frc-captcha', styles.container, invertColors ? styles.invertedColors : undefined)}
    />
  )
}

export function withCaptchaProvider<P extends JSX.IntrinsicAttributes>(Component: ComponentType<P>) {
  return function WithCaptchaProvider(props: P) {
    return (
      <CaptchaProvider>
        <Component {...props} />
      </CaptchaProvider>
    )
  }
}

export default Captcha
