'use client'
import { Heading } from '@betterplace/design-system/client'
import { toLowerCase } from '@betterplace/utils'
import { useDonationFormValues } from '@/donationPages/_dependencies/helpers'
import { useTranslations } from 'next-intl'

function HolderTitle() {
  const [paymentMethod] = useDonationFormValues(['payment_method'])
  const t = useTranslations('nextjs.donate')
  return <Heading level="h200">{t(`holder_title.${toLowerCase(paymentMethod)}`)}</Heading>
}

export default HolderTitle
