'use client'
import CodonationCalculator from '@/donationForm/_dependencies/CodonationCalculator'
import {
  useDonationFormConfig,
  useDonationFormContext,
  useDonationFormValues,
} from '@/donationPages/_dependencies/helpers'
import { useEffect } from 'react'

function UpdateCodonationOnDonationChange() {
  const { formState, setValue } = useDonationFormContext()
  const {
    touchedFields: { codonation_cents: isTouched },
  } = formState
  const { showCodonation, codonationCents: codonationCentsOverride } = useDonationFormConfig()
  const [amountCents, defaultAmountCents] = useDonationFormValues(['amount_cents', 'default_amount_cents'])

  useEffect(() => {
    if (isTouched || !showCodonation) return
    let codonationCents: number | null = null
    if (codonationCentsOverride !== undefined && amountCents === defaultAmountCents) {
      codonationCents = codonationCentsOverride
    }
    codonationCents ??= CodonationCalculator.suggest(amountCents) ?? 0
    setValue('codonation_cents', codonationCents)
  }, [amountCents, codonationCentsOverride, defaultAmountCents, isTouched, setValue, showCodonation])
  return null
}

export default UpdateCodonationOnDonationChange
