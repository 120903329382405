'use client'

import analytics, { FeatureFlagCallback, FeatureFlagKey } from '@betterplace/product-analytics'
import { useCallback, useEffect, useState } from 'react'

function useFeatureFlag(name: FeatureFlagKey, value: string | boolean, skipWhile?: () => boolean) {
  const [featureFlagMatches, setFeatureFlagMatches] = useState<boolean | undefined>(
    !skipWhile?.() ? analytics.getFeatureFlag(name) === value : undefined
  )

  const setFeatureFlag: FeatureFlagCallback = useCallback(
    (flagValue) => {
      setFeatureFlagMatches(flagValue === value)
    },
    [value]
  )

  useEffect(() => {
    if (skipWhile?.()) return
    return analytics.listenToFeatureFlag(name, setFeatureFlag)
  }, [name, setFeatureFlag, skipWhile])

  return featureFlagMatches
}

export default useFeatureFlag
