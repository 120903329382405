'use client'
import { createContext, useContext } from 'react'
import type { DonationFormConfig } from '@/donationForm/types'

const DonationFormConfigContext = createContext<DonationFormConfig>({} as DonationFormConfig)

export function useDonationFormConfig() {
  const context = useContext(DonationFormConfigContext)
  return context
}

export function DonationFormConfigProvider({
  children,
  config,
}: {
  children: React.ReactNode
  config: DonationFormConfig
}) {
  return <DonationFormConfigContext.Provider value={config}>{children}</DonationFormConfigContext.Provider>
}
export default DonationFormConfigContext
