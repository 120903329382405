'use client'

import Captcha from '@/components/Captcha'
import { useCallback } from 'react'
import { useDonationFormConfig, useDonationFormContext } from '@/donationPages/_dependencies/helpers'
import type { CaptchaProps } from '@/components/Captcha/types'

const CAPTCHA_SOLUTION_FIELD_NAME = 'captcha_solution'
const CAPTCHA_ERROR_FIELD_NAME = '_captcha_error'
const CAPTCHA_COMPLETE = '_captcha_complete'

function DonationFormCaptcha() {
  const { captchaSiteKey, captchaEndpoint } = useDonationFormConfig()
  const { setValue } = useDonationFormContext()
  const onCaptchaSuccess: CaptchaProps['onSuccess'] = useCallback(
    (solution) => {
      setValue(CAPTCHA_COMPLETE, true, { shouldValidate: true, shouldDirty: true, shouldTouch: true })
      setValue(CAPTCHA_SOLUTION_FIELD_NAME, solution, { shouldValidate: true, shouldDirty: true, shouldTouch: true })
    },
    [setValue]
  )

  const onCaptchaError: CaptchaProps['onError'] = useCallback(
    (error, value) => {
      setValue(CAPTCHA_COMPLETE, true)
      setValue(CAPTCHA_ERROR_FIELD_NAME, value)
      console.error(error)
    },
    [setValue]
  )

  const onCaptchaReset = useCallback(() => {
    // this causes a problem, because it invalidates the form again
    // and therefore replaces the backend error
    // therefore we cannot have two different error messages
    // until we rebuild the whole form error handling
    setValue(CAPTCHA_COMPLETE, false)
  }, [setValue])

  const isFakeCaptcha = process.env.NEXT_PUBLIC_DEPLOY_ENVIRONMENT === 'test'

  if (isFakeCaptcha) {
    return null
  }

  return (
    <>
      <Captcha
        siteKey={captchaSiteKey}
        endpoint={captchaEndpoint}
        onSuccess={onCaptchaSuccess}
        onError={onCaptchaError}
        onReset={onCaptchaReset}
        fieldName={CAPTCHA_SOLUTION_FIELD_NAME}
      />
    </>
  )
}

export default DonationFormCaptcha
