import { OperationReturn } from '@/helpers/operations'
import { useActionFormContext } from '@/form/ActionForm'
import type { DonationFormValues } from '@/donationForm/types'
import type { processDonationFormAction } from '@/donationPages/_dependencies/operations/processDonationAction'

function useDonationFormContext() {
  return useActionFormContext<DonationFormValues, OperationReturn<typeof processDonationFormAction>['data']>()
}

export default useDonationFormContext
