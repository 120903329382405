'use client'

import analytics, { getDonationFormType } from '@betterplace/product-analytics'
import sanitize from 'sanitize-html'
import { sendGTMEvent } from '@next/third-parties/google'
import { useDonationFormConfig } from '@/donationPages/_dependencies/helpers'
import { useEffect } from 'react'
import { usePaymentMethodsToRender } from '@/donationPages/_dependencies/NativePaymentMethods'

function useTrackDonationFormVisit() {
  const {
    receiverName,
    receiverId,
    receiverType,
    receiverGtmBrand: brand,
    channel,
    showCodonation,
  } = useDonationFormConfig()

  const { paymentMethodsToRender, nativePaymentsLoading } = usePaymentMethodsToRender()

  useEffect(() => {
    if (nativePaymentsLoading) return

    sendGTMEvent({
      event: 'eeCheckout',
      ecommerce: {
        currencyCode: 'EUR',
        checkout: {
          actionField: { step: 1, option: 'form loaded' },
          products: [
            {
              name: sanitize(receiverName),
              id: `${receiverType}#${receiverId}`,
              brand,
              quantity: 1,
            },
          ],
        },
      },
    })
    analytics.donationFormOpened({
      donation_form_type: getDonationFormType({ receiverId, receiverType, channel, showCodonation }),
      receiver_type: receiverType,
      id: receiverId,
      available_payment_methods: paymentMethodsToRender,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [receiverName, receiverId, receiverType, brand, channel, showCodonation, nativePaymentsLoading])
}

export default useTrackDonationFormVisit
