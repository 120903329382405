import { PaymentMethods } from '@betterplace/api-graphql-types'
import { createContext, useContext } from 'react'
import type { NativePaymentAvailable } from './types'

type PaymentMethodAvailableContext = {
  loading: boolean
  available: NativePaymentAvailable
}

const NativePaymentMethodsContext = createContext<PaymentMethodAvailableContext>({
  loading: true,
  available: {
    [PaymentMethods.StripeApplePay]: false,
    [PaymentMethods.StripeGooglePay]: false,
  },
})

export function useNativePaymentMethodsContext() {
  return useContext(NativePaymentMethodsContext)
}

export default NativePaymentMethodsContext
