import DonationFormField from '@/donationPages/_dependencies/components/DonationFormField'
import DonationFormRequiredField from '@/donationPages/_dependencies/components/DonationFormRequiredField'
import { useMemo } from 'react'

function useRequiredComponent(required?: boolean) {
  const FieldComponent = useMemo(() => (required ? DonationFormRequiredField : DonationFormField), [required])
  return FieldComponent
}

export default useRequiredComponent
