/* eslint-disable import/exports-last */
import { locale, slug } from '@/schema'
import { z } from 'zod'

const slugWithLocaleSchema = z.object({
  locale,
  slug,
})

export default slugWithLocaleSchema
