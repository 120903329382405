import { keysOf } from '@betterplace/utils'
import { useDonationFormContext } from '@/donationPages/_dependencies/helpers'
import { useMemo } from 'react'

function useFieldErrorsKeys() {
  const { formState } = useDonationFormContext()
  const { errors } = formState
  const keys = useMemo(
    () =>
      keysOf(errors ?? {}).filter(
        (key) => key !== 'payment_method' && key !== '_captcha_complete' && key !== 'captcha_solution'
      ),
    [errors]
  )
  return keys
}

/**
 * Function skips the "general" payment_method error, so that we only evaluate the validity of the field itself
 * @returns boolean
 */
function useIsFormValid() {
  const { formState } = useDonationFormContext()
  const { isValid } = formState
  const keys = useFieldErrorsKeys()
  return isValid || !keys.length
}

export default useIsFormValid
