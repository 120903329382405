/* eslint-disable import/exports-last */
import analytics, { getDonationFormType } from '@betterplace/product-analytics'
import { ReceiverTypes } from '@betterplace/api-graphql-types'
import { ZodIssueCode } from 'zod'
import { entriesOf, stringify } from '@betterplace/utils'
import { getTranslatorWithFallback } from '@/i18n'
import { useCallback } from 'react'
import { usePaymentMethodsToRender } from '@/donationPages/_dependencies/NativePaymentMethods'
import { useStep } from '@/donationPages/iframe/[receiver-type]/[slug]/_dependencies/IFrameLayout/StepProvider'
import { useTranslations } from 'next-intl'
import type { DonationFormValues } from '@/donationForm/types'
import type { ErrorHandler } from '@/form/ActionForm/useActionForm'
import type { NextIntlKeys, Translator } from '@/i18n/types'

function isATranslationKey(type: unknown) {
  return !['validate', 'required', ...Object.values(ZodIssueCode)].includes(type as string)
}

type UseMinimalErrorHandlerProps = {
  receiverId: string
  receiverType: ReceiverTypes
  showCodonation: boolean
  channel: string
  setBusy: (busy: boolean) => void
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  errors: any
}

export function useMinimalErrorHandler() {
  const { setStep } = useStep()
  const { paymentMethodsToRender } = usePaymentMethodsToRender()
  return useCallback(
    ({ receiverId, receiverType, showCodonation, channel, setBusy, errors }: UseMinimalErrorHandlerProps) => {
      setStep(1)
      setBusy(false)
      analytics.donationErrorMessageDisplayed({
        donation_form_type: getDonationFormType({
          receiverId,
          receiverType,
          channel,
          showCodonation,
        }),
        id: receiverId,
        receiver_type: receiverType,
        error: stringify(errors),
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        errors,
        available_payment_methods: paymentMethodsToRender,
      })
    },
    [setStep, paymentMethodsToRender]
  )
}

function useErrorHandler(): ErrorHandler<DonationFormValues> {
  const t_ = useTranslations('nextjs.donate.errors')
  const minimalHandler = useMinimalErrorHandler()
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const handler: ErrorHandler<DonationFormValues> = useCallback(
    (errors, values, { setValue, setBusy, setError }, options = {}) => {
      if (options.shouldSetErrors) {
        const t = getTranslatorWithFallback<Translator<'nextjs.donate.errors'>>(t_)
        // TODO: This is kind of insane, we should probably move the translation logic to the server, as the issue is with the server error translation messages, address after DEV-25752
        for (const [key, error] of entriesOf(errors)) {
          const { message: message_, type: type_ } = error!
          let message = message_ as string
          if (type_ && isATranslationKey(type_)) {
            message = t(type_ as NextIntlKeys<'nextjs.donate.errors'>, { defaultValue: message })
          }
          if (message === undefined || message === null) continue
          setError(key, { message, type: 'server' })
          if (key === 'root') continue
          // @ts-expect-error for whatever reason we can get empty keys here TODO: check why and fix
          if (key === '') continue
          const value = values[key]
          setValue(key, value, { shouldTouch: true })
        }
      }
      minimalHandler({
        receiverId: values.receiver_id,
        receiverType: values.receiver_type,
        showCodonation: values.show_codonation,
        channel: values.channel,
        setBusy,
        errors,
      })
    },
    [t_, minimalHandler]
  )

  return handler
}

export default useErrorHandler
