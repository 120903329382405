'use client'
import useLocale from '@/i18n/useLocale'
import { Elements, useElements, useStripe } from '@stripe/react-stripe-js'
import { NativePaymentMethodsProvider } from '@/donationPages/_dependencies/NativePaymentMethods'
import { type ReactNode, createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { type Stripe, loadStripe } from '@stripe/stripe-js'
import { stripeNativePaymentElementsOptions } from './components/formParts/SubmitButton/StripeNativePaymentButton'
import { useMessageContext } from '@/components/Messages'
import { useOfflineMessage } from '@/helpers/hooks'
import { useTranslations } from 'next-intl'
import { withRetry } from '@/helpers/utils'
import type { StripeOptions } from '@/donationForm/types'

const StripeContext = createContext<StripeOptions>({})

export function useStripeContext() {
  return useContext(StripeContext)
}

export function useIsStripeLoaded() {
  const { stripe } = useStripeContext()
  return !!stripe
}

export function useStripePromise(stripeKey: string) {
  const [promise, setPromise] = useState<Promise<Stripe | null> | null>(null)
  const { addMessage, removeMessage } = useMessageContext()
  const messageIdRef = useRef<string | null>(null)
  const removeErrorMessage = useCallback(
    () => messageIdRef.current && removeMessage(messageIdRef.current),
    [removeMessage]
  )
  const t = useTranslations('nextjs')
  const onError = useCallback(
    (attempt: number) => {
      removeErrorMessage()
      messageIdRef.current = addMessage({
        type: 'error',
        message: `${t('donate.errors.stripe_loading')} ${t('errors.messages.retrying', { attempt })}`,
      })
    },
    [addMessage, removeErrorMessage, t]
  )

  const onFailure = useCallback(() => {
    removeErrorMessage()
    messageIdRef.current = addMessage({
      type: 'error',
      message: t('donate.errors.stripe_loading'),
    })
  }, [addMessage, removeErrorMessage, t])

  const loadStripeWithRetry = useMemo(
    () => withRetry(loadStripe, { onError, onFailure, onSuccess: removeErrorMessage, baseDelayMs: 2500 }),
    [onError, onFailure, removeErrorMessage]
  )

  useEffect(() => {
    if (typeof window === 'undefined' || !stripeKey) return
    setPromise(loadStripeWithRetry(stripeKey))
    return () => {
      setPromise(null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stripeKey])
  return promise
}

export function StripeContextProvider({ children }: { children: ReactNode }) {
  const stripe = useStripe()
  const elements = useElements()
  return <StripeContext.Provider value={{ stripe, elements }}>{children}</StripeContext.Provider>
}

export function StripeProvider({ children, stripePublicKey }: { children: ReactNode; stripePublicKey: string }) {
  const stripePromise = useStripePromise(stripePublicKey)
  const locale = useLocale()
  useOfflineMessage()
  return (
    <Elements
      stripe={stripePromise}
      // The minimum amount is 50cents, otherwise the checkout element won't initialise properly
      options={{ locale, ...stripeNativePaymentElementsOptions }}
    >
      <StripeContextProvider>{children}</StripeContextProvider>
    </Elements>
  )
}

export function withStripeProvider<P extends { config: { stripePublicKey: string } }>(
  Component: React.ComponentType<P>
) {
  return function WithStripeProvider(props: P) {
    return (
      <StripeProvider stripePublicKey={props.config.stripePublicKey}>
        <NativePaymentMethodsProvider>
          <Component {...props} />
        </NativePaymentMethodsProvider>
      </StripeProvider>
    )
  }
}
