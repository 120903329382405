import analytics, { getDonationFormType } from '@betterplace/product-analytics'
import { useCallback, useState } from 'react'
import { useDonationFormConfig, useDonationFormValues } from '@/donationPages/_dependencies/helpers'

function useCodonationModalState() {
  const { receiverId, receiverType, channel, showCodonation } = useDonationFormConfig()
  const [codonationCents] = useDonationFormValues(['codonation_cents'])

  const [modalShown, setShowCodonationModal] = useState(false)
  const openModal = useCallback(() => {
    setShowCodonationModal(true)
    analytics.coDonationModalOpened({
      donation_form_type: getDonationFormType({
        receiverId,
        receiverType,
        channel,
        showCodonation,
      }),
      id: receiverId,
      receiver_type: receiverType,
    })
    const ele = document.activeElement as HTMLElement | null
    ele?.blur()
  }, [channel, receiverId, receiverType, showCodonation])
  const hideModal = useCallback(() => setShowCodonationModal(false), [])

  return { modalShown, openModal, hideModal, codonationCents }
}

export default useCodonationModalState
