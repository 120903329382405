import NativePaymentMethodsContext from './NativePaymentMethodsContext'
import { PaymentMethods } from '@betterplace/api-graphql-types'
import { type ReactNode, useEffect, useState } from 'react'
import type { NativePaymentAvailable, NativePaymentMethod } from './types'

function getSupportedMethods(paymentMethod: NativePaymentMethod): [PaymentMethodData] {
  if (paymentMethod === PaymentMethods.StripeApplePay) {
    return [
      {
        supportedMethods: 'https://apple.com/apple-pay',
        data: {
          environment: 'TEST',
        },
      },
    ]
  }
  return [
    {
      supportedMethods: 'https://google.com/pay',
      data: {
        currencyCode: 'EUR',
        countryCode: 'DE',
      },
    },
  ]
}

async function isPaymentMethodAvailable(paymentMethod: NativePaymentMethod): Promise<boolean> {
  if (typeof PaymentRequest !== 'undefined') {
    try {
      return await new PaymentRequest(getSupportedMethods(paymentMethod), {
        total: {
          label: 'test',
          amount: {
            currency: 'EUR',
            value: '0.00',
          },
        },
      }).canMakePayment()
    } catch {}
  }
  // browser does not support PaymentRequest
  if (paymentMethod === PaymentMethods.StripeApplePay && typeof window.ApplePaySession !== 'undefined') {
    return (window.ApplePaySession as { canMakePayments: () => Promise<boolean> }).canMakePayments() ?? false
  }
  if (paymentMethod === PaymentMethods.StripeGooglePay && typeof window.google !== 'undefined') {
    // assumes google sdk is loaded
    return (window.google as { payments?: { isReadyToPay: () => Promise<boolean> } }).payments?.isReadyToPay() ?? false
  }
  return false
}

const initialAvailabilityState = {
  [PaymentMethods.StripeApplePay]: false,
  [PaymentMethods.StripeGooglePay]: false,
}

function NativePaymentMethodsProvider({ children }: { children?: ReactNode }) {
  const [available, setAvailable] = useState<NativePaymentAvailable>(initialAvailabilityState)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setLoading(true)
    Promise.allSettled([
      isPaymentMethodAvailable(PaymentMethods.StripeApplePay),
      isPaymentMethodAvailable(PaymentMethods.StripeGooglePay),
    ])
      .then((results) => {
        const [applePay, googlePay] = results
        if (applePay.status === 'fulfilled') {
          setAvailable((prevState) => ({ ...prevState, [PaymentMethods.StripeApplePay]: applePay.value }))
        } else {
          setAvailable((prevState) => ({ ...prevState, [PaymentMethods.StripeApplePay]: false }))
        }
        if (googlePay.status === 'fulfilled') {
          setAvailable((prevState) => ({ ...prevState, [PaymentMethods.StripeGooglePay]: googlePay.value }))
        } else {
          setAvailable((prevState) => ({ ...prevState, [PaymentMethods.StripeGooglePay]: false }))
        }
      })
      .catch((error) => {
        console.error(`error from isPaymentMethodAvailable:`, error)
        setAvailable(initialAvailabilityState)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [])

  return (
    <NativePaymentMethodsContext.Provider value={{ available, loading }}>
      {children}
    </NativePaymentMethodsContext.Provider>
  )
}

export default NativePaymentMethodsProvider
