import analytics, { convertCentsToEuro, getDonationFormType } from '@betterplace/product-analytics'
import useFinalizeHandler from './useFinalizeHandler'
import useTrackDonationFormVisit from './useTrackDonationFormVisit'
import { donationFormSchema, getDonationFormErrorMap } from '@/donationPages/_dependencies/schemas'
import { keysOf, stringify } from '@betterplace/utils'
import { processDonationFormAction } from '@/donationPages/_dependencies/operations/processDonationAction'
import { sendGTMEvent } from '@next/third-parties/google'
import { useActionForm } from '@/form/ActionForm'
import { useCallback } from 'react'
import { useCaptchaContext } from '@/components/Captcha'
import { useErrorHandler } from '@/donationPages/_dependencies/helpers'
import { usePaymentMethodsToRender } from '../../NativePaymentMethods'
import { useTranslations } from 'next-intl'
import type { DonationFormValues } from '@/donationForm/types'
import type { ErrorHandler } from '@/form/ActionForm/useActionForm'

function useDonationForm(defaultValues: DonationFormValues, id?: string) {
  const t = useTranslations()
  const { paymentMethodsToRender } = usePaymentMethodsToRender()
  useTrackDonationFormVisit()

  const { solve, reset } = useCaptchaContext()
  const beforeFormSubmit = useCallback(async () => {
    await solve()
  }, [solve])

  const afterClientSubmitSuccess = useCallback(
    ({
      payment_method,
      amount_cents,
      interval,
      show_amount,
      show_user,
      show_codonation,
      codonation_cents,
      display_name,
      receipt_desired,
      newsletter,
      message,
      receiver_id,
      receiver_type,
      channel,
    }: DonationFormValues) => {
      analytics.donationSubmitted({
        donation_amount: convertCentsToEuro(amount_cents),
        recurring_donation: interval,
        payment_method: payment_method,
        codonation_amount: convertCentsToEuro(codonation_cents),
        show_amount_selected: show_amount,
        show_name_selected: !!(show_user || display_name),
        donation_receipt_requested: receipt_desired,
        platform_newsletter_selected: newsletter,
        comment_added: !!message,
        id: receiver_id,
        receiver_type: receiver_type,
        available_payment_methods: paymentMethodsToRender,
        donation_form_type: getDonationFormType({
          channel,
          receiverId: receiver_id,
          receiverType: receiver_type,
          showCodonation: show_codonation,
        }),
      })
      sendGTMEvent({
        event: 'eeCheckout',
        ecommerce: { currencyCode: 'EUR', checkout: { actionField: { step: 2, option: payment_method } } },
      })
    },
    [paymentMethodsToRender]
  )

  const afterSubmitSuccess = useFinalizeHandler(t)
  const handleServerErrors = useErrorHandler()
  const afterSubmitError: ErrorHandler<DonationFormValues> = useCallback(
    async (errors, values, tools, options) => {
      await handleServerErrors(errors, values, tools, options)
      const { _captcha_complete, ...rest } = errors
      if (!Object.keys(rest).length) return
      reset()
    },
    [handleServerErrors, reset]
  )
  const afterClientSubmitError: ErrorHandler<DonationFormValues> = useCallback(
    (errors, values, tools) => {
      // TODO: This seems super hacky, I'm copying legacy behaviour, but there should be a better way to achieve this with RHF. Check if this is already addressed by changes in the useActionForm
      // As we're displaying errors only for touched fields, we need to mark them as touched
      for (const key of keysOf(errors)) {
        // TODO: Check how exactly am I supposed to deal with root errors in RHF
        if (key === 'root') continue
        // @ts-expect-error For whatever reason we can get an empty key here, TODO: Find out why and fix it
        if (key === '') continue
        tools.setValue(key, values[key], { shouldTouch: true })
      }
      const { receiver_id: receiverId, receiver_type: receiverType, channel, show_codonation: showCodonation } = values
      analytics.donationErrorMessageDisplayed({
        donation_form_type: getDonationFormType({
          receiverId,
          receiverType,
          channel,
          showCodonation,
        }),
        available_payment_methods: paymentMethodsToRender,
        id: receiverId,
        receiver_type: receiverType,
        error: stringify(errors),
        errors,
      })
    },
    [paymentMethodsToRender]
  )

  const form = useActionForm({
    action: processDonationFormAction,
    actionContext: { returnToOnAuthErrorUrl: '' }, // this is not a secure action anyways, we don't care about the returnToOnAuthErrorUrl
    schema: donationFormSchema,
    getErrorMap: getDonationFormErrorMap,
    defaultValues,
    defaultErrorMessage: t('nextjs.errors.messages.default'),
    afterClientSubmitSuccess,
    afterClientSubmitError,
    afterSubmitSuccess,
    afterSubmitError,
    beforeFormSubmit,
    mode: 'all',
    reValidateMode: 'onChange',
    id,
  })

  return form
}

export default useDonationForm
