/* eslint-disable import/exports-last */
import { ActionFormSubmitErrorTypes } from '@/form/ActionForm/useActionForm/useActionForm'
import { NextIntlKeys } from '@/i18n/types'
import { report } from '@/errorReporting'
import { useCallback, useState } from 'react'
import { useDonationFormContext, useRedirectHandler } from '@/donationPages/_dependencies/helpers'
import { useElements, useStripe } from '@stripe/react-stripe-js'
import { useMinimalErrorHandler } from '@/donationPages/_dependencies/helpers/useErrorHandler'
import { useTranslations } from 'next-intl'
import type { Maybe } from '@/types'
import type { StripeError, StripeExpressCheckoutElementConfirmEvent } from '@stripe/stripe-js'

const nonDeclineErrorCodes = ['expired_card', 'incorrect_cvc', 'processing_error'] as const
const knownDeclineCodes = ['generic_decline', 'insufficient_funds', 'lost_card', 'stolen_card', 'fraudulent'] as const
type DonateErrorNextIntlKeys = NextIntlKeys<'nextjs.donate.errors'>
type NonDeclineErrorCode = (typeof nonDeclineErrorCodes)[number]
type KnownDeclineCode = (typeof knownDeclineCodes)[number]

function isNonDeclineErrorCode(code: string | undefined): code is NonDeclineErrorCode {
  if (!code) return false
  return nonDeclineErrorCodes.includes(code as NonDeclineErrorCode)
}

function isKnownDeclineCode(code: string | undefined): code is KnownDeclineCode {
  if (!code) return false
  return knownDeclineCodes.includes(code as KnownDeclineCode)
}

function getCardErrorMessageKey(error: StripeError): DonateErrorNextIntlKeys {
  if (error.code === 'card_declined' && isKnownDeclineCode(error.decline_code)) {
    return `stripe_native_payment_card_declined_${error.decline_code}`
  }

  if (error.code && isNonDeclineErrorCode(error.code)) {
    return `stripe_native_payment_${error.code}`
  }

  return 'unknown_error'
}

export function useNormalizePaymentError() {
  const t = useTranslations('nextjs.donate.errors')

  const normalizePaymentError = useCallback(
    (error: StripeError) => {
      switch (error.type) {
        case 'card_error': {
          const errorMessageKey = getCardErrorMessageKey(error)
          return t(errorMessageKey)
        }
        case 'rate_limit_error':
        case 'api_connection_error':
        case 'api_error':
          return t('try_again_later_or_different_payment_method')
        case 'authentication_error':
        case 'invalid_request_error':
        case 'idempotency_error':
          return t('try_again_later_or_different_payment_method')
        case 'validation_error':
          // we still need to figure out how to trigger this (maybe with different payment method within google/apple pay)
          return t('unknown_error')
        default:
          return t('unknown_error')
      }
    },
    [t]
  )
  return normalizePaymentError
}

function useFormSubmit() {
  const { submit, getValues, setBusy } = useDonationFormContext()
  const stripe = useStripe()
  const elements = useElements()
  const t = useTranslations('nextjs.donate.errors')
  const [displayError, setDisplayError] = useState<string | undefined>(undefined)
  const normalizePaymentError = useNormalizePaymentError()
  const redirectHandler = useRedirectHandler()
  const errorHandler = useMinimalErrorHandler()

  const onConfirm = useCallback(
    async (_: StripeExpressCheckoutElementConfirmEvent) => {
      if (!stripe || !elements) return

      const handleError = (error: StripeError, errorMessage?: string) => {
        setDisplayError(errorMessage ?? t('try_again_later_or_different_payment_method'))
        report(submitError)
        const values = getValues()
        errorHandler({
          receiverId: values.receiver_id,
          receiverType: values.receiver_type,
          channel: values.channel,
          showCodonation: values.show_codonation,
          setBusy,
          errors: { stripe: error.message },
        })
      }

      const { error: submitError } = await elements.submit()
      if (submitError) {
        handleError(submitError)
        return
      }

      const { paymentMethod, error: paymentMethodError } = await stripe.createPaymentMethod({ elements })
      if (paymentMethodError) {
        handleError(paymentMethodError)
        return
      }

      try {
        // eslint-disable-next-line no-var
        var { data } = await submit()
      } catch (error_) {
        const error = error_ as Maybe<Error>
        if (error?.message === ActionFormSubmitErrorTypes.ValidationError) return
        setDisplayError(t('try_again_later_or_different_payment_method'))
        report(error)
        return
      }
      setBusy(true)
      const { redirectTo, donationId, stripePaymentIntentClientSecret } = data
      if (!stripePaymentIntentClientSecret || !redirectTo || !donationId) return

      const { error } = await stripe.confirmPayment({
        clientSecret: stripePaymentIntentClientSecret,
        confirmParams: {
          payment_method: paymentMethod.id,
        },
        redirect: 'if_required',
      })

      if (error && error.type) {
        const message = normalizePaymentError(error)
        handleError(error, message)
        return
      }
      await redirectHandler(redirectTo, donationId, getValues())
    },
    [stripe, elements, redirectHandler, getValues, t, errorHandler, setBusy, submit, normalizePaymentError]
  )

  return { onConfirm, displayError }
}

export default useFormSubmit
