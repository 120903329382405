'use client'
import useLocale from '@/i18n/useLocale'
import { DonationAmountSumProps } from './types'
import { formatCents } from '@betterplace/utils'
import { useDonationFormValues } from '@/donationPages/_dependencies/helpers'

function DonationAmountSum({ precision, fields }: DonationAmountSumProps) {
  const locale = useLocale()
  const values = useDonationFormValues(fields)
  // If precision is undefined, the fallback is to who precision 2 if needed and 0 if amount ends with `,00`.
  const cents = values.reduce((sum: number, value) => sum + Number(value ? value : 0), 0)
  return <span>{formatCents(locale, cents, { precision })}</span>
}
export default DonationAmountSum
