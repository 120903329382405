'use client'

import UpdateCodonationOnDonationChange from './UpdateCodonationOnDonationChange'
import useDonationForm from '../useDonationForm'
import useLocale from '@/i18n/useLocale'
import { ActionFormProvider } from '@/form/ActionForm'
import { DonationFormConfigProvider } from '../../DonationFormConfigContext'
import { MessagesProvider } from '@/components/Messages'
import { withCaptchaProvider } from '@/components/Captcha'
import { withStripeProvider } from '@/donationPages/_dependencies/StripeContext'
import type { ComponentType } from 'react'
import type { DonationFormConfig, DonationFormProps } from '@/donationForm/types'
function withConfigProvider<P extends { config: DonationFormConfig }>(FormComponent: ComponentType<P>) {
  return function WithConfigProvider(props: P) {
    const { config } = props
    return (
      <DonationFormConfigProvider config={config}>
        <FormComponent {...props} />
      </DonationFormConfigProvider>
    )
  }
}

function withFormProviders(FormComponent: ComponentType<DonationFormProps>) {
  return withCaptchaProvider(
    withConfigProvider(
      withStripeProvider(function Form({
        config,
        initialFFs,
        initialFormValues,
      }: DonationFormProps & JSX.IntrinsicAttributes) {
        const { action, onSubmit, id, ...providerProps } = useDonationForm(initialFormValues)
        const locale = useLocale()
        return (
          <MessagesProvider locale={locale}>
            <ActionFormProvider {...providerProps}>
              <UpdateCodonationOnDonationChange />
              <FormComponent
                config={config}
                action={action}
                onSubmit={onSubmit}
                id={id}
                initialFFs={initialFFs}
                initialFormValues={initialFormValues}
              />
            </ActionFormProvider>
          </MessagesProvider>
        )
      })
    )
  )
}
export default withFormProviders
