'use client'

import DonationAmountSum from '@/donationPages/_dependencies/components/DonationAmountSum'
import FormSubmitButton from '@/form/FormSubmitButton'
import PaypalButton from '@/donationPages/_dependencies/components/formParts/SubmitButton/PaypalButton'
import StripeNativePaymentButton from './StripeNativePaymentButton'
import classNames from 'classnames'
import styles from './SubmitButton.module.css'
import { PaymentMethods } from '@betterplace/api-graphql-types/enums'

import {
  useDonationFormConfig,
  useDonationFormContext,
  useDonationFormValues,
} from '@/donationPages/_dependencies/helpers'
import { useTranslations } from 'next-intl'

function SubmitButton({ className: className_ }: { className?: string }) {
  const { paypalClientId } = useDonationFormConfig()
  const [paymentMethod] = useDonationFormValues(['payment_method'])
  const { formState } = useDonationFormContext()
  const { isSubmitting } = formState
  const t = useTranslations()
  const className = classNames(styles.button, className_)

  const onClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (isSubmitting) {
      e.preventDefault()
    }
  }

  switch (paymentMethod) {
    case PaymentMethods.Paypal:
      return <PaypalButton clientId={paypalClientId} />
    case PaymentMethods.StripeApplePay:
    case PaymentMethods.StripeGooglePay:
      return <StripeNativePaymentButton paymentMethod={paymentMethod} />
    case PaymentMethods.DirectDeposit:
      return (
        <FormSubmitButton className={className} onClick={onClick} data-testid="donate-form-donate-button">
          {t('nextjs.donate.submit_button.direct_deposit')}
        </FormSubmitButton>
      )

    default:
      return (
        <>
          <FormSubmitButton className={className} onClick={onClick} data-testid="donate-form-donate-button">
            <span>{t('nextjs.donate.submit_button.default.before_amount')}</span>
            <DonationAmountSum fields={['amount_cents', 'codonation_cents']} />
            <span>{t('nextjs.donate.submit_button.default.after_amount')}</span>
          </FormSubmitButton>
        </>
      )
  }
}

export default SubmitButton
