'use client'

/* eslint-disable import/exports-last */
import ErrorMessage from '@/form/ErrorMessage'
import React, { useCallback } from 'react'
import styles from '../StripeFields.module.css'
import useResetFieldOnPaymentMethodChange from '../useResetFieldOnPaymentMethodChange'
import { CardElement } from '@stripe/react-stripe-js'
import { Heading } from '@betterplace/design-system/client'
import { PaymentMethods } from '@betterplace/api-graphql-types/enums'
import { StripeCardElement, StripeElementStyle } from '@stripe/stripe-js'
import { default as coreTokens } from '@betterplace/design-tokens/core.json'
import { default as semanticTokens } from '@betterplace/design-tokens/semantic.json'
import {
  useDonationFormConfig,
  useDonationFormContext,
  useDonationFormValues,
} from '@/donationPages/_dependencies/helpers'
import { useIsStripeLoaded } from '@/donationPages/_dependencies/StripeContext'
import { useTranslations } from 'next-intl'
import type { CardElementChangeHandler, CardElementStripeProps } from './types'
import type { NextIntlKeys } from '@/i18n/types'

const fgContentPrimaryColor = semanticTokens['betterplace-color-fg-content-primary']
const fgMuteColor = semanticTokens['betterplace-color-fg-mute']
const fgDangerColor = semanticTokens['betterplace-color-fg-danger']
const fontWeight = coreTokens['betterplace-font-weights-regular']

/**
 * Using rem for lineHeight and fontSize produces unexpected results:
 * - not right font size
 * - or if both of them are set the input height grows to 3x bigger
 */
const style: StripeElementStyle = {
  base: {
    'color': fgContentPrimaryColor,
    'lineHeight': '24px',
    'fontFamily': '"Fira Sans", Helvetica, sans-serif', // Fira Sans will only work once we enable it the element options
    'fontSmoothing': 'antialiased',
    'fontSize': '16px',
    'fontWeight': fontWeight,
    '::placeholder': {
      color: fgMuteColor,
    },
  },
  invalid: {
    color: fgDangerColor,
    iconColor: fgDangerColor,
  },
}

const cardElementProps: CardElementStripeProps = {
  options: {
    hidePostalCode: true,
    style,
  },
}

type DonateNextIntlKeys = NextIntlKeys<'nextjs.donate'>

function StripeCCFields() {
  const t = useTranslations('nextjs.donate')
  const { stripePublicKey } = useDonationFormConfig()
  const { setError, setValue, setBusy, clearErrors } = useDonationFormContext()
  const [paymentMethod] = useDonationFormValues(['payment_method'])

  const handleChange: CardElementChangeHandler = useCallback(
    (event) => {
      setValue('stripe_token', stripePublicKey, { shouldTouch: true })
      if (!event.error) {
        return clearErrors('stripe_token')
      }
      setError('stripe_token', { message: t(`errors.stripe_cc_${event.error.code}` as DonateNextIntlKeys) })
      setBusy(false)
    },
    [setValue, stripePublicKey, setError, t, setBusy, clearErrors]
  )
  const isStripeLoaded = useIsStripeLoaded()
  const onReady = useResetFieldOnPaymentMethodChange<StripeCardElement>(paymentMethod)
  const show = paymentMethod === PaymentMethods.StripeCc
  if (!isStripeLoaded)
    return (
      <div className={styles.stripeNotLoadedMessage}>
        <Heading level="h300" className={styles.label}>
          {t('stripe_cc_fields.headline')}
        </Heading>
        <p>{t('errors.stripe_cc_unavailable')}</p>
      </div>
    )

  // because of the new library's behaviour unmounting Stripe components will cause an exception,
  // unless it's removed together with its provider
  return (
    <div style={{ display: show ? 'block' : 'none' }} className={styles.stripeField}>
      <Heading level="h300" className={styles.label}>
        {t('stripe_cc_fields.headline')}*
      </Heading>
      <CardElement onReady={onReady} onChange={handleChange} id="card-element" {...cardElementProps} />
      {/* No accessible error message for you */}
      <ErrorMessage name="stripe_token" className={styles.stripeFieldError} />
    </div>
  )
}

export default StripeCCFields
