/* eslint-disable consistent-default-export-name/default-import-match-filename */
/* eslint-disable import/exports-last */
import ApplePayLogo from '@betterplace/assets/images/logos/applepay.svg'
import GooglePayLogo from '@betterplace/assets/images/logos/googlepay.svg'
import Image from 'next/image'
import classNames from 'classnames'
import styles from './StripeNativePaymentButton.module.css'
import useDelayLoadingNativePaymentMethod from './useDelayLoadingNativePaymentMethod'
import useFormSubmit from './useFormSubmit'
import useIsFormValid from '../useIsFormValid'
import useIsMounted from '@/helpers/hooks/useIsMounted'
import { ExpressCheckoutElement, useElements } from '@stripe/react-stripe-js'
import { Icon } from '@betterplace/design-system/server'
import { type NativePaymentMethod } from '@/donationPages/_dependencies/NativePaymentMethods'
import { PaymentMethods } from '@betterplace/api-graphql-types'
import { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { useDonationFormContext, useDonationFormValues } from '@/donationPages/_dependencies/helpers'
import { useTranslations } from 'next-intl'
import type {
  StripeExpressCheckoutElementClickEvent,
  StripeExpressCheckoutElementOptions,
  StripeExpressCheckoutElementReadyEvent,
} from '@stripe/stripe-js'

const buttonHeight = 48
const logoMap = {
  [PaymentMethods.StripeApplePay]: ApplePayLogo as string,
  [PaymentMethods.StripeGooglePay]: GooglePayLogo as string,
}

function PaymentInProgressButton() {
  return (
    <div className={classNames(styles.loadingButtonWrapper)}>
      <button
        className={styles.button}
        style={{ height: buttonHeight + 1 }}
        disabled
        onClick={(e) => e.preventDefault()}
        type="button"
      >
        <Icon name="spinner" style={{ opacity: 1 }} />
      </button>
    </div>
  )
}

function DefaultDummyButton({
  onClick,
  disabled,
  paymentMethod,
  ready,
}: {
  disabled: boolean
  onClick?: () => void
  paymentMethod: NativePaymentMethod
  ready?: boolean
}) {
  const t = useTranslations()

  return (
    <div className={classNames(styles.wrapper, styles.dummy)}>
      <button
        className={styles.button}
        style={{ height: buttonHeight + 1 }}
        disabled={disabled}
        onClick={onClick}
        type="submit"
      >
        {!ready && (
          <span className={styles.readyButtonContent}>
            <Icon name="spinner" style={{ fill: 'white' }} size="400" />
            <Image style={{ display: 'inline-block' }} src={logoMap[paymentMethod]} alt={t('nextjs.core.submit')} />
          </span>
        )}
      </button>
    </div>
  )
}

function DummyStripeNativePaymentButton({
  onClick: onClick_,
  isSubmitting,
  ready,
  paymentMethod,
  isPaymentInProgress,
}: {
  isSubmitting?: boolean
  ready?: boolean
  paymentMethod: NativePaymentMethod
  onClick?: () => void
  isPaymentInProgress: boolean
}) {
  const valid = useIsFormValid()
  const disabled = !useIsMounted()
  const onClick = useCallback(() => {
    if (isSubmitting || !valid) return
    onClick_?.()
  }, [onClick_, isSubmitting, valid])

  if (isPaymentInProgress) return <PaymentInProgressButton />

  return <DefaultDummyButton onClick={onClick} disabled={disabled} paymentMethod={paymentMethod} ready={ready} />
}

function getStripePaymentOptions(paymentMethod: NativePaymentMethod): StripeExpressCheckoutElementOptions {
  const googlePayKey = 'googlePay'
  const applePayKey = 'applePay'
  const activeKey = paymentMethod === PaymentMethods.StripeApplePay ? applePayKey : googlePayKey
  const inactiveKey = paymentMethod === PaymentMethods.StripeApplePay ? googlePayKey : applePayKey
  return {
    paymentMethods: {
      [activeKey]: 'always',
      [inactiveKey]: 'never',
    },
    buttonType: {
      [activeKey]: 'donate',
    },
    buttonHeight,
    layout: {
      maxColumns: 1,
      maxRows: 0,
      overflow: 'never',
    },

    buttonTheme: {
      [activeKey]: 'black',
    },
  }
}

function useButtonOptions(paymentMethod: NativePaymentMethod) {
  return useMemo(() => getStripePaymentOptions(paymentMethod), [paymentMethod])
}

function UpdateStripeFromTotal() {
  const elements = useElements()
  const [amount, codonation] = useDonationFormValues(['amount_cents', 'codonation_cents'])
  const total = useMemo(() => amount + codonation, [amount, codonation])

  useEffect(() => {
    if (!elements) return
    if (!total) return
    elements.update({
      amount: total,
    })
  }, [elements, total])
  return null
}

function StripeNativePaymentButtonInner({
  paymentMethod,
  onClick: onClick_,
}: {
  paymentMethod: NativePaymentMethod
  onClick?: () => void
}) {
  const { onConfirm, displayError } = useFormSubmit()

  const [ready, setReady] = useState(false)
  const {
    formState: { isSubmitting },
    busy,
  } = useDonationFormContext()
  const valid = useIsFormValid()
  const disabled = isSubmitting || !valid
  const onReady = useCallback((_: StripeExpressCheckoutElementReadyEvent) => {
    setReady(true)
  }, [])

  useEffect(() => {
    return () => {
      setReady(false)
    }
  }, [])
  const onClick = useCallback(
    (event: StripeExpressCheckoutElementClickEvent) => {
      onClick_?.()
      event.resolve()
    },
    [onClick_]
  )

  const loading = useDelayLoadingNativePaymentMethod()

  const options = useButtonOptions(paymentMethod)
  return (
    <>
      {displayError && <p className={styles.errorMessage}>{displayError}</p>}
      <div className={styles.container}>
        <UpdateStripeFromTotal />
        <div
          className={styles.wrapper}
          style={{ pointerEvents: ready && !disabled ? 'auto' : 'none' }}
          // otherwise it's not showing up
          inert={ready && !disabled ? undefined : ('' as unknown as boolean)}
        >
          {!loading && (
            <ExpressCheckoutElement
              options={options}
              onReady={onReady}
              onConfirm={onConfirm}
              onClick={onClick}
              onLoadError={(error) => {
                console.error(error)
                setReady(false)
              }}
            />
          )}
        </div>
        {(!ready || disabled || busy) && (
          <DummyStripeNativePaymentButton
            paymentMethod={paymentMethod}
            isSubmitting={isSubmitting}
            onClick={onClick_}
            ready={ready}
            isPaymentInProgress={busy}
          />
        )}
      </div>
    </>
  )
}

const StripeNativePaymentButton = memo(StripeNativePaymentButtonInner)
export default StripeNativePaymentButton
