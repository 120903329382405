import useNativePaymentMethods from '../useNativePaymentMethods'
import { PaymentMethods } from '@betterplace/api-graphql-types'
import { useCallback, useMemo } from 'react'
import { useDonationFormConfig } from '@/donationPages/_dependencies/helpers'
import { useNativePaymentMethodsContext } from '../NativePaymentMethodsContext'
import type { NativePaymentMethod } from '../types'

function usePaymentMethodsToRender() {
  const { loading, available } = useNativePaymentMethodsContext()
  const shouldUseNativePaymentMethods = useNativePaymentMethods()
  const { paymentMethods, recurringPaymentMethods } = useDonationFormConfig()
  const filterOutUnavailablePaymentMethods = useCallback(
    (methods: Array<PaymentMethods>) => {
      if (!methods?.length) return []
      if (loading || !shouldUseNativePaymentMethods)
        return methods.filter(
          (method) => method !== PaymentMethods.StripeApplePay && method !== PaymentMethods.StripeGooglePay
        )
      return methods.filter((method) => !(method in available) || available[method as NativePaymentMethod])
    },
    [loading, available, shouldUseNativePaymentMethods]
  )
  const paymentMethodsToRender = useMemo(
    () => filterOutUnavailablePaymentMethods(paymentMethods),
    [paymentMethods, filterOutUnavailablePaymentMethods]
  )

  const recurringPaymentMethodsToRender = useMemo(
    () => filterOutUnavailablePaymentMethods(recurringPaymentMethods),
    [recurringPaymentMethods, filterOutUnavailablePaymentMethods]
  )

  return { paymentMethodsToRender, recurringPaymentMethodsToRender, nativePaymentsLoading: loading }
}

export default usePaymentMethodsToRender
