import useFeatureFlag from '@/analytics/featureFlags/useFeatureFlag'
import { keysOf } from '@betterplace/utils'
import { useCallback, useMemo } from 'react'
import { useNativePaymentMethodsContext } from '../NativePaymentMethodsContext'

function useNativePaymentMethodsAreAvailable() {
  const { loading, available } = useNativePaymentMethodsContext()
  const anyAvailable = useMemo(() => !!keysOf(available).find((key) => available[key]), [available])
  return !loading && anyAvailable
}

function useNativePaymentMethods() {
  const areNativeMethodsAvailable = useNativePaymentMethodsAreAvailable()
  const skipTheCheck = useCallback(() => !areNativeMethodsAvailable, [areNativeMethodsAvailable])
  return useFeatureFlag('apple-pay-google-pay', 'test', skipTheCheck)
}

export default useNativePaymentMethods
