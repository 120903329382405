import { useEffect, useState } from 'react'
import { useNativePaymentMethodsContext } from '@/donationPages/_dependencies/NativePaymentMethods'

function useDelayLoadingNativePaymentMethod() {
  const { loading } = useNativePaymentMethodsContext()
  const [nativeLoading, setNativeLoading] = useState(true)
  useEffect(() => {
    const timeout = setTimeout(() => {
      setNativeLoading(loading)
    }, 1)

    return () => clearTimeout(timeout)
  }, [loading])

  return nativeLoading
}

export default useDelayLoadingNativePaymentMethod
