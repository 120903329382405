'use client'

import { createContext, useContext, useState } from 'react'

const StepContext = createContext({
  step: 1,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setStep: (step: number) => {},
})

export function useStep() {
  return useContext(StepContext)
}

export default function StepProvider({ children }: { children: React.ReactNode }) {
  const [step, setStep] = useState(1)
  return <StepContext.Provider value={{ step, setStep }}>{children}</StepContext.Provider>
}
