import { PaymentMethods } from '@betterplace/api-graphql-types/enums'

import { useDonationFormConfig, useDonationFormValues } from '@/donationPages/_dependencies/helpers'
import { useEffect } from 'react'

import useNativePaymentMethods from '../useNativePaymentMethods'
import usePaymentMethodsToRender from '../usePaymentMethodsToRender'
import { useFormContext } from 'react-hook-form'

const DEFAULT_PAYMENT_METHOD_PRIORITY = [
  PaymentMethods.StripeApplePay,
  PaymentMethods.StripeGooglePay,
  PaymentMethods.StripeSepaDebit,
  PaymentMethods.Paypal,
]

const STREAMING_PAYMENT_METHOD_PRIORITY = [
  PaymentMethods.StripeApplePay,
  PaymentMethods.StripeGooglePay,
  PaymentMethods.Paypal,
  PaymentMethods.StripeSepaDebit,
]

function useFallbackPriorityQueue() {
  const { showDisplayName: isStreamingEvent } = useDonationFormConfig()
  if (isStreamingEvent) return STREAMING_PAYMENT_METHOD_PRIORITY
  return DEFAULT_PAYMENT_METHOD_PRIORITY
}

function useSelectDefaultPaymentMethod() {
  const fallbackPriorityQueue = useFallbackPriorityQueue()
  const { paymentMethodsToRender, nativePaymentsLoading } = usePaymentMethodsToRender()
  const shouldUseNativePaymentMethods = useNativePaymentMethods()
  const { originalDefaultPaymentMethod, defaultPaymentMethod } = useDonationFormConfig()
  const { setValue } = useFormContext()
  const [selectedPaymentMethod] = useDonationFormValues(['payment_method'])

  useEffect(() => {
    if (!shouldUseNativePaymentMethods) return
    if (originalDefaultPaymentMethod !== defaultPaymentMethod) return
    if (
      selectedPaymentMethod === PaymentMethods.StripeApplePay ||
      selectedPaymentMethod === PaymentMethods.StripeGooglePay
    )
      return

    setValue('_default_payment_method', PaymentMethods.StripeApplePay)
    setValue('payment_method', PaymentMethods.StripeApplePay)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldUseNativePaymentMethods])

  useEffect(() => {
    if (nativePaymentsLoading) return
    // flag is not yet resolved
    if (shouldUseNativePaymentMethods === undefined) return
    if (paymentMethodsToRender.includes(selectedPaymentMethod)) return

    const preferred =
      fallbackPriorityQueue.find((value) => paymentMethodsToRender.includes(value)) || selectedPaymentMethod

    if (selectedPaymentMethod === preferred) return

    setValue('_default_payment_method', preferred)
    setValue('payment_method', preferred)
  }, [
    fallbackPriorityQueue,
    paymentMethodsToRender,
    selectedPaymentMethod,
    setValue,
    nativePaymentsLoading,
    shouldUseNativePaymentMethods,
  ])
}

export default useSelectDefaultPaymentMethod
