import styles from './DirectDepositInfo.module.css'
import { PaymentMethods } from '@betterplace/api-graphql-types/enums'
import { useTranslations } from 'next-intl'

function DirectDepositInfo({ paymentMethod }: { paymentMethod: PaymentMethods }) {
  const t = useTranslations()
  if (paymentMethod !== PaymentMethods.DirectDeposit) return null
  return (
    <div className={styles.directDepositCard}>
      <p className={styles.directDepositText}>{t('nextjs.donate.direct_deposit_info')}</p>
    </div>
  )
}

export default DirectDepositInfo
